<template>
  <div class="home">
    <div id="panel">
      <div id="welcome">欢迎使用SmartColorDesigner</div>
      <el-button
        type="success"
        style="width: 100px"
        id="toGuide"
        round
        size="large"
        @click="toGuide"
        >快速开始</el-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
  methods: {
    toGuide() {
      this.$router.push("/color-matching");
    },
  },
};
</script>
<style>
#toGuide {
  justify-self: center;
  position: relative;
  box-shadow: 8px 6px 30px 0px rgba(149, 212, 117, 0.69);
}
#welcome {
  align-self: center;
  justify-self: center;
  position: relative;
  top: 30%;
  font-size: 30px;
  margin: 20px;
}
#panel {
  display: grid;

  width: 600px;
  height: 400px;
  background-color: #fff;
  box-shadow: 1.9px 1.4px 6px rgba(0, 0, 0, 0.025),
    4.5px 3.5px 14.4px rgba(0, 0, 0, 0.031),
    8.4px 6.5px 27px rgba(0, 0, 0, 0.034),
    15px 11.6px 48.2px rgba(0, 0, 0, 0.036),
    28px 21.7px 90.2px rgba(0, 0, 0, 0.039), 67px 52px 216px rgba(0, 0, 0, 0.05);
  align-self: center;
  justify-self: center;
  position: relative;
}
.home {
  height: 100%;
  display: grid;
  background-image: url("../assets/homebg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.home::before {
  background-image: url("../assets/homebg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  filter: blur(5px);
  z-index: -1;
}
</style>
