<template>
  <el-container class="mainContainer">
    <el-header><h1>SmartColorDesigner智能配色工具</h1></el-header>
    <el-container>
      <el-aside width="200px"
        ><el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
        >
          <el-menu-item index="1" @click="this.$router.push('/')"
            ><span>首页</span></el-menu-item
          >
          <el-menu-item index="2" @click="this.$router.push('/guide')"
            ><span>说明</span></el-menu-item
          >
          <el-menu-item index="3" @click="this.$router.push('/color-matching')"
            ><span>配色</span></el-menu-item
          >
          <el-menu-item index="4" @click="this.$router.push('/about')"
            ><span>关于</span></el-menu-item
          >
        </el-menu></el-aside
      >
      <el-container>
        <el-main><router-view> </router-view></el-main>
        <el-footer
          ><span id="copyright">©2023 ZhaoYide.AllRightsReserved</span>
          <br />
          <a href="https://support.qq.com/product/540023" id="feedback"
            >欢迎提出您的建议：https://support.qq.com/product/540023</a
          ></el-footer
        >
      </el-container>
    </el-container>
  </el-container>
</template>

<style>
.mainContainer {
  width: 98vw;
  height: 95vh;
}
.el-footer {
  display: grid;
}
#copyright,
#feedback {
  color: #cfd3dc;
  font-size: 8px;
  align-self: center;
  justify-self: center;
  position: relative;
}
</style>
